// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Image_image__hyq-M {
  width: 304px;
  height: 258px;
  object-fit: cover;
  border-radius: 23px;
}
@media (min-width: 768px) {
  .Image_image__hyq-M {
    width: 425px;
    height: 360px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/WhoIAm/AboutMe/Image/Image.module.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,aAAA;EACA,iBAAA;EACA,mBAAA;AAFF;ACJE;EDEF;IAOI,YAAA;IACA,aAAA;EADF;AACF","sourcesContent":["@use \"/src/constants\";\n@use \"/src/mixins\";\n\n.image {\n  width: 304px;\n  height: 258px;\n  object-fit: cover;\n  border-radius: 23px;\n\n  @include mixins.responsive(constants.$screen-medium) {\n    width: 425px;\n    height: 360px;\n  }\n}","@mixin responsive($query) {\n  @media ( min-width: $query ) {\n    @content;\n  }\n}\n\n@mixin ifLess($query) {\n  @media ( max-width: $query ) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `Image_image__hyq-M`
};
export default ___CSS_LOADER_EXPORT___;
