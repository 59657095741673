// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyExperience_container__gbwTC .MyExperience_slider__C03Zl {
  margin-top: 36px;
}
@media (min-width: 768px) {
  .MyExperience_container__gbwTC .MyExperience_slider__C03Zl {
    margin-top: 78px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MyExperience/MyExperience.module.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAOE;EACE,gBAAA;AANJ;ACDE;EDMA;IAII,gBAAA;EALJ;AACF","sourcesContent":["@use \"/src/constants\";\n@use \"/src/mixins\";\n\n.container {\n  .title {\n  }\n\n  .slider {\n    margin-top: 36px;\n\n    @include mixins.responsive(constants.$screen-medium) {\n      margin-top: 78px;\n    }\n  }\n}\n","@mixin responsive($query) {\n  @media ( min-width: $query ) {\n    @content;\n  }\n}\n\n@mixin ifLess($query) {\n  @media ( max-width: $query ) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MyExperience_container__gbwTC`,
	"slider": `MyExperience_slider__C03Zl`
};
export default ___CSS_LOADER_EXPORT___;
