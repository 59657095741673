// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Slider_container__QPR5T {
  max-width: 100vw;
}
@media (min-width: 768px) {
  .Slider_container__QPR5T {
    display: none;
  }
}
.Slider_container__QPR5T .Slider_sliderContainer__7hJOK {
  height: 241px;
}
.Slider_container__QPR5T .Slider_sliderContainer__7hJOK .Slider_slider__KTTAc {
  position: absolute;
  width: 100vw;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 calc((100vw - 198px) / 2);
}
.Slider_container__QPR5T .Slider_sliderContainer__7hJOK .Slider_slider__KTTAc .Slider_swiperSlide__3SnOe {
  display: flex;
  justify-content: center;
}
.Slider_container__QPR5T .Slider_sliderContainer__7hJOK .Slider_slider__KTTAc .Slider_numbers__jolgN {
  margin-top: 10px;
}
.Slider_container__QPR5T .Slider_description__-PYXy {
  margin-top: 36px;
}`, "",{"version":3,"sources":["webpack://./src/components/MyServices/Slider/Slider.module.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;AAFF;ACDE;EDEF;IAII,aAAA;EADF;AACF;AAGE;EACE,aAAA;AADJ;AAGI;EACE,kBAAA;EACA,YAAA;EACA,OAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EAGA,oCAAA;AAHN;AAKM;EACE,aAAA;EACA,uBAAA;AAHR;AAMM;EACE,gBAAA;AAJR;AASE;EACE,gBAAA;AAPJ","sourcesContent":["@use \"/src/constants\";\n@use \"/src/mixins\";\n\n.container {\n  max-width: 100vw;\n\n  @include mixins.responsive(constants.$screen-medium) {\n    display: none;\n  }\n\n  .sliderContainer {\n    height: 241px;\n\n    .slider {\n      position: absolute;\n      width: 100vw;\n      left: 0;\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n\n      $padding: calc((100vw - 198px) / 2);\n      padding: 0 $padding;\n\n      .swiperSlide {\n        display: flex;\n        justify-content: center;\n      }\n\n      .numbers {\n        margin-top: 10px;\n      }\n    }\n  }\n\n  .description {\n    margin-top: 36px;\n  }\n}\n","@mixin responsive($query) {\n  @media ( min-width: $query ) {\n    @content;\n  }\n}\n\n@mixin ifLess($query) {\n  @media ( max-width: $query ) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Slider_container__QPR5T`,
	"sliderContainer": `Slider_sliderContainer__7hJOK`,
	"slider": `Slider_slider__KTTAc`,
	"swiperSlide": `Slider_swiperSlide__3SnOe`,
	"numbers": `Slider_numbers__jolgN`,
	"description": `Slider_description__-PYXy`
};
export default ___CSS_LOADER_EXPORT___;
