// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.List_container__qsjX2 {
  display: none;
  width: 858px;
}
@media (min-width: 768px) {
  .List_container__qsjX2 {
    display: flex;
    flex-direction: column;
  }
}
.List_container__qsjX2 .List_line__3qDK7 {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/MyServices/List/List.module.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,YAAA;AAFF;ACFE;EDEF;IAKI,aAAA;IACA,sBAAA;EADF;AACF;AAGE;EACE,aAAA;EACA,8BAAA;AADJ","sourcesContent":["@use \"/src/constants\";\n@use \"/src/mixins\";\n\n.container {\n  display: none;\n  width: 858px;\n\n  @include mixins.responsive(constants.$screen-medium) {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .line {\n    display: flex;\n    justify-content: space-between;\n  }\n}","@mixin responsive($query) {\n  @media ( min-width: $query ) {\n    @content;\n  }\n}\n\n@mixin ifLess($query) {\n  @media ( max-width: $query ) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `List_container__qsjX2`,
	"line": `List_line__3qDK7`
};
export default ___CSS_LOADER_EXPORT___;
