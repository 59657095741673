// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Text_text__7g0H- {
  text-align: center;
}
@media (min-width: 768px) {
  .Text_text__7g0H- {
    text-align: justify;
  }
}
.Text_text__7g0H- .Text_highlighted__m4Bhx {
  font-family: "Cascadia Code";
  color: #AFE571;
}`, "",{"version":3,"sources":["webpack://./src/components/WhoIAm/Greeting/Text/Text.module.scss","webpack://./src/mixins.scss","webpack://./src/constants.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;AAFF;ACDE;EDEF;IAII,mBAAA;EADF;AACF;AAGE;EACE,4BAAA;EACA,cEXgB;AFUpB","sourcesContent":["@use \"/src/constants\";\n@use \"/src/mixins\";\n\n.text {\n  text-align: center;\n\n  @include mixins.responsive(constants.$screen-medium) {\n    text-align: justify;\n  }\n\n  .highlighted {\n    font-family: \"Cascadia Code\";\n    color: constants.$color-light-green;\n  }\n}\n","@mixin responsive($query) {\n  @media ( min-width: $query ) {\n    @content;\n  }\n}\n\n@mixin ifLess($query) {\n  @media ( max-width: $query ) {\n    @content;\n  }\n}","// colors\n$color-light-green: #AFE571;\n$color-dark-blue: #233F4C;\n$color-light-blue: #3C687D;\n$color-error: #FF4949;\n\n// desktop default fonts\n$font-default-desktop-h1: 700 44px \"Gilroy\";\n$font-default-desktop-h2: 600 38px \"Gilroy\";\n$font-default-desktop-h3: 600 28px \"Gilroy\";\n$font-default-desktop-h4: 600 28px \"Gilroy\";\n$font-default-desktop-p: 400 18px \"Gilroy\";\n\n// desktop default line heights\n$font-line-height-desktop-h1: 50px;\n$font-line-height-desktop-h2: 50px;\n$font-line-height-desktop-h3: 28px;\n$font-line-height-desktop-h4: 50px;\n$font-line-height-desktop-p: 26px;\n\n// mobile default fonts\n$font-default-mobile-h1: 600 26px \"Gilroy\";\n$font-default-mobile-h2: 600 26px \"Gilroy\";\n$font-default-mobile-h3: 600 26px \"Gilroy\";\n$font-default-mobile-h4: 600 20px \"Gilroy\";\n$font-default-mobile-p: 400 16px \"Gilroy\";\n\n// mobile default line heights\n$font-line-height-mobile-h1: 34px;\n$font-line-height-mobile-h2: 34px;\n$font-line-height-mobile-h3: 26px;\n$font-line-height-mobile-h4: 20px;\n$font-line-height-mobile-p: 26px;\n\n// breakpoints\n$screen-medium: 768px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `Text_text__7g0H-`,
	"highlighted": `Text_highlighted__m4Bhx`
};
export default ___CSS_LOADER_EXPORT___;
