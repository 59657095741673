// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Greeting_container__YwnXX {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .Greeting_container__YwnXX {
    display: grid;
    grid-template-areas: "title image" "text image" "button image";
    grid-column-gap: 64px;
    grid-row-gap: 32px;
  }
}
.Greeting_container__YwnXX .Greeting_image__wGDow {
  grid-area: image;
}
.Greeting_container__YwnXX .Greeting_title__uKlno {
  margin-top: 36px;
  grid-area: title;
}
@media (min-width: 768px) {
  .Greeting_container__YwnXX .Greeting_title__uKlno {
    margin-top: 0;
  }
}
.Greeting_container__YwnXX .Greeting_text__tMTZh {
  margin-top: 16px;
  grid-area: text;
}
@media (min-width: 768px) {
  .Greeting_container__YwnXX .Greeting_text__tMTZh {
    margin-top: 0;
  }
}
.Greeting_container__YwnXX .Greeting_button__A\\+h0T {
  margin-top: 16px;
  grid-area: button;
}
@media (min-width: 768px) {
  .Greeting_container__YwnXX .Greeting_button__A\\+h0T {
    margin-top: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/WhoIAm/Greeting/Greeting.module.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAFF;ACHE;EDEF;IAMI,aAAA;IACA,8DACE;IAGF,qBAAA;IACA,kBAAA;EAJF;AACF;AAME;EACE,gBAAA;AAJJ;AAOE;EACE,gBAAA;EACA,gBAAA;AALJ;AClBE;EDqBA;IAKI,aAAA;EAJJ;AACF;AAOE;EACE,gBAAA;EACA,eAAA;AALJ;AC3BE;ED8BA;IAKI,aAAA;EAJJ;AACF;AAOE;EACE,gBAAA;EACA,iBAAA;AALJ;ACpCE;EDuCA;IAKI,aAAA;EAJJ;AACF","sourcesContent":["@use \"/src/constants\";\n@use \"/src/mixins\";\n\n.container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  @include mixins.responsive(constants.$screen-medium) {\n    display: grid;\n    grid-template-areas:\n      \"title image\"\n      \"text image\"\n      \"button image\";\n    grid-column-gap: 64px;\n    grid-row-gap: 32px;\n  }\n\n  .image {\n    grid-area: image;\n  }\n\n  .title {\n    margin-top: 36px;\n    grid-area: title;\n\n    @include mixins.responsive(constants.$screen-medium) {\n      margin-top: 0;\n    }\n  }\n\n  .text {\n    margin-top: 16px;\n    grid-area: text;\n\n    @include mixins.responsive(constants.$screen-medium) {\n      margin-top: 0;\n    }\n  }\n\n  .button {\n    margin-top: 16px;\n    grid-area: button;\n\n    @include mixins.responsive(constants.$screen-medium) {\n      margin-top: 0;\n    }\n  }\n}","@mixin responsive($query) {\n  @media ( min-width: $query ) {\n    @content;\n  }\n}\n\n@mixin ifLess($query) {\n  @media ( max-width: $query ) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Greeting_container__YwnXX`,
	"image": `Greeting_image__wGDow`,
	"title": `Greeting_title__uKlno`,
	"text": `Greeting_text__tMTZh`,
	"button": `Greeting_button__A+h0T`
};
export default ___CSS_LOADER_EXPORT___;
