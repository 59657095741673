// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.3);
  width: 10px;
  height: 10px;
  opacity: 1;
  transform: translateY(15px);
}
@media (min-width: 768px) {
  .swiper-pagination-bullet {
    display: none;
  }
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/MyExperience/Slider/Slider.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAGA;EACE,0CAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,2BAAA;AAFF;ACLE;EDEF;IAQI,aAAA;EADF;AACF;AAGE;EACE,uBAAA;AADJ","sourcesContent":["@use \"/src/constants\";\n@use \"/src/mixins\";\n\n.swiper-pagination-bullet {\n  background-color: rgba(255, 255, 255, 0.3);\n  width: 10px;\n  height: 10px;\n  opacity: 1;\n  transform: translateY(15px);\n\n  @include mixins.responsive(constants.$screen-medium) {\n    display: none;\n  }\n\n  &.swiper-pagination-bullet-active {\n    background-color: white;\n  }\n}","@mixin responsive($query) {\n  @media ( min-width: $query ) {\n    @content;\n  }\n}\n\n@mixin ifLess($query) {\n  @media ( max-width: $query ) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
