// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes MessageSent_appearsMobile__sqUmL {
  0% {
    width: 0;
    height: 0;
  }
  100% {
    width: 181px;
    height: 181px;
  }
}
@keyframes MessageSent_appearsDesktop__yYyco {
  0% {
    width: 0;
    height: 0;
  }
  100% {
    width: 232px;
    height: 232px;
  }
}
.MessageSent_messageSent__FjD9- {
  position: absolute;
  width: 181px;
  height: 181px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  top: 38%;
  opacity: 0.9;
  animation: MessageSent_appearsMobile__sqUmL 1s;
}
@media (min-width: 768px) {
  .MessageSent_messageSent__FjD9- {
    width: 232px;
    height: 232px;
    animation: MessageSent_appearsDesktop__yYyco 1s;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/WhoIAm/ContactMe/MessageSent/MessageSent.animations.scss","webpack://./src/components/WhoIAm/ContactMe/MessageSent/MessageSent.module.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAAA;EACE;IACE,QAAA;IACA,SAAA;ECCF;EDCA;IACE,YAAA;IACA,aAAA;ECCF;AACF;ADEA;EACE;IACE,QAAA;IACA,SAAA;ECAF;EDEA;IACE,YAAA;IACA,aAAA;ECAF;AACF;AAfA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,4BAAA;EACA,0BAAA;EACA,QAAA;EACA,YAAA;EAEA,8CAAA;AAgBF;AC5BE;EDGF;IAYI,YAAA;IACA,aAAA;IAEA,+CAAA;EAgBF;AACF","sourcesContent":["@keyframes appearsMobile {\n  0% {\n    width: 0;\n    height: 0;\n  }\n  100% {\n    width: 181px;\n    height: 181px;\n  }\n}\n\n@keyframes appearsDesktop {\n  0% {\n    width: 0;\n    height: 0;\n  }\n  100% {\n    width: 232px;\n    height: 232px;\n  }\n}","@use \"/src/constants\";\n@use \"/src/mixins\";\n@use \"MessageSent.animations\";\n\n.messageSent {\n  position: absolute;\n  width: 181px;\n  height: 181px;\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n  top: 38%;\n  opacity: 0.9;\n\n  animation: appearsMobile 1s;\n\n  @include mixins.responsive(constants.$screen-medium) {\n    width: 232px;\n    height: 232px;\n\n    animation: appearsDesktop 1s;\n  }\n}","@mixin responsive($query) {\n  @media ( min-width: $query ) {\n    @content;\n  }\n}\n\n@mixin ifLess($query) {\n  @media ( max-width: $query ) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageSent": `MessageSent_messageSent__FjD9-`,
	"appearsMobile": `MessageSent_appearsMobile__sqUmL`,
	"appearsDesktop": `MessageSent_appearsDesktop__yYyco`
};
export default ___CSS_LOADER_EXPORT___;
