// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Map_mapContainer__Mmj24 {
  position: relative;
  width: 100%;
  height: 310px;
  margin-top: 46px;
  overflow: hidden;
  border-radius: 16px;
}
@media (min-width: 768px) {
  .Map_mapContainer__Mmj24 {
    width: 734px;
    height: 317px;
  }
}
.Map_mapContainer__Mmj24 .Map_cover__a1LpZ {
  position: absolute;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  transition-duration: 0.5s;
}
.Map_mapContainer__Mmj24 .Map_cover__a1LpZ:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
.Map_mapContainer__Mmj24 .Map_map__CwYxD {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
.Map_mapContainer__Mmj24 .Map_map__CwYxD.Map_blurred__0J7y1 {
  filter: blur(8px) brightness(60%);
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Contacts/Map/Map.module.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AAFF;ACNE;EDEF;IASI,YAAA;IACA,aAAA;EADF;AACF;AAGE;EACE,kBAAA;EACA,6BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,WAAA;EACA,YAAA;EAEA,eAAA;EACA,UAAA;EAEA,yBAAA;AAJJ;AAMI;EACE,2CAAA;AAJN;AAYE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AAVJ;AAYI;EACE,iCAAA;EACA,oBAAA;AAVN","sourcesContent":["@use \"/src/constants\";\n@use \"/src/mixins\";\n\n.mapContainer {\n  position: relative;\n  width: 100%;\n  height: 310px;\n  margin-top: 46px;\n  overflow: hidden;\n  border-radius: 16px;\n\n  @include mixins.responsive(constants.$screen-medium) {\n    width: 734px;\n    height: 317px;\n  }\n\n  .cover {\n    position: absolute;\n    background-color: transparent;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    width: 100%;\n    height: 100%;\n\n    cursor: pointer;\n    z-index: 2;\n\n    transition-duration: 0.5s;\n\n    &:hover {\n      background-color: rgba(255, 255, 255, 0.08);\n\n    }\n\n    .tapIcon {\n    }\n  }\n\n  .map {\n    width: 100%;\n    height: 100%;\n    border-radius: 16px;\n\n    &.blurred {\n      filter: blur(8px) brightness(60%);\n      pointer-events: none;\n    }\n  }\n}","@mixin responsive($query) {\n  @media ( min-width: $query ) {\n    @content;\n  }\n}\n\n@mixin ifLess($query) {\n  @media ( max-width: $query ) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapContainer": `Map_mapContainer__Mmj24`,
	"cover": `Map_cover__a1LpZ`,
	"map": `Map_map__CwYxD`,
	"blurred": `Map_blurred__0J7y1`
};
export default ___CSS_LOADER_EXPORT___;
