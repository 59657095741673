// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutMe_container__eCAYd {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 92px;
}
@media (min-width: 768px) {
  .AboutMe_container__eCAYd {
    display: grid;
    margin-top: 162px;
    grid-template-areas: "title title" "image slider";
    grid-column-gap: 111px;
    grid-row-gap: 78px;
  }
}
.AboutMe_container__eCAYd .AboutMe_image__\\+ZujQ {
  grid-area: image;
}
.AboutMe_container__eCAYd .AboutMe_title__qAZQ0 {
  margin-top: 36px;
  grid-area: title;
}
@media (min-width: 768px) {
  .AboutMe_container__eCAYd .AboutMe_title__qAZQ0 {
    margin-top: 0;
  }
}
.AboutMe_container__eCAYd .AboutMe_slider__dbu4R {
  margin-top: 26px;
  grid-area: slider;
}
@media (min-width: 768px) {
  .AboutMe_container__eCAYd .AboutMe_slider__dbu4R {
    margin-top: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/WhoIAm/AboutMe/AboutMe.module.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EAEA,gBAAA;AAHF;ACJE;EDEF;IAQI,aAAA;IACA,iBAAA;IACA,iDACE;IAEF,sBAAA;IACA,kBAAA;EAJF;AACF;AAME;EACE,gBAAA;AAJJ;AAOE;EACE,gBAAA;EACA,gBAAA;AALJ;ACpBE;EDuBA;IAKI,aAAA;EAJJ;AACF;AAOE;EACE,gBAAA;EACA,iBAAA;AALJ;AC7BE;EDgCA;IAKI,aAAA;EAJJ;AACF","sourcesContent":["@use \"/src/constants\";\n@use \"/src/mixins\";\n\n.container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  margin-top: 92px;\n\n  @include mixins.responsive(constants.$screen-medium) {\n    display: grid;\n    margin-top: 162px;\n    grid-template-areas:\n      \"title title\"\n      \"image slider\";\n    grid-column-gap: 111px;\n    grid-row-gap: 78px;\n  }\n\n  .image {\n    grid-area: image;\n  }\n\n  .title {\n    margin-top: 36px;\n    grid-area: title;\n\n    @include mixins.responsive(constants.$screen-medium) {\n      margin-top: 0;\n    }\n  }\n\n  .slider {\n    margin-top: 26px;\n    grid-area: slider;\n\n    @include mixins.responsive(constants.$screen-medium) {\n      margin-top: 0;\n    }\n  }\n}","@mixin responsive($query) {\n  @media ( min-width: $query ) {\n    @content;\n  }\n}\n\n@mixin ifLess($query) {\n  @media ( max-width: $query ) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AboutMe_container__eCAYd`,
	"image": `AboutMe_image__+ZujQ`,
	"title": `AboutMe_title__qAZQ0`,
	"slider": `AboutMe_slider__dbu4R`
};
export default ___CSS_LOADER_EXPORT___;
