// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExperienceList_container__Sf6Cf {
  display: none;
  margin-top: 78px;
}
@media (min-width: 768px) {
  .ExperienceList_container__Sf6Cf {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.ExperienceList_container__Sf6Cf .ExperienceList_content__KsU3O {
  width: 754px;
}`, "",{"version":3,"sources":["webpack://./src/components/MyExperience/ExperienceList/ExperienceList.module.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,gBAAA;AAFF;ACFE;EDEF;IAKI,aAAA;IACA,sBAAA;IACA,mBAAA;EADF;AACF;AAGE;EACE,YAAA;AADJ","sourcesContent":["@use \"/src/constants\";\n@use \"/src/mixins\";\n\n.container {\n  display: none;\n  margin-top: 78px;\n\n  @include mixins.responsive(constants.$screen-medium) {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n\n  .content {\n    width: 754px;\n  }\n}","@mixin responsive($query) {\n  @media ( min-width: $query ) {\n    @content;\n  }\n}\n\n@mixin ifLess($query) {\n  @media ( max-width: $query ) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ExperienceList_container__Sf6Cf`,
	"content": `ExperienceList_content__KsU3O`
};
export default ___CSS_LOADER_EXPORT___;
